<template>
  <div class="app-calendar overflow-hidden border">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="loading"
        class="loading-overlay"
      >
        <b-spinner
          style="width: 4em; height: 4em"
          class="mt-1"
        />
      </div>
    </transition>
    <div class="row no-gutters">
      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import plLocale from '@fullcalendar/core/locales/pl'
import { BSpinner } from 'bootstrap-vue'
import dayjs from 'dayjs'

export default {
  components: {
    FullCalendar,
    BSpinner,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    events() {
      const list = []
      if (this.$store.state.trainingCourse.calendar) {
        this.$store.state.trainingCourse.calendar['hydra:member'].forEach(trainingCourse => {
          const event = {
            id: trainingCourse.id,
            title: trainingCourse.name,
            url: `/training-courses/details/${trainingCourse.id}`,
            start: dayjs(trainingCourse.date).toDate(),
            end: dayjs(trainingCourse.endDate).toDate(),
          }
          list.push(event)
        })
      }

      return list
    },

    calendarOptions() {
      return {
        initialView: 'dayGridMonth',
        plugins: [dayGridPlugin],
        locales: [plLocale],
        locale: 'pl',
        eventClick: this.redirectToEvent,
        events: this.events,
      }
    },
  },
  beforeMount() {
    this.fetchData()

    if (this.isEmployee || this.isTrainer) {
      this.$root.$emit('pageTitle:set', 'Twoje nadchodzące szkolenia')
    } else {
      this.$root.$emit('pageTitle:set', 'Nadchodzące szkolenia')
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$store.dispatch('trainingCourse/fetchCalendar').finally(() => { this.loading = false })
    },
    redirectToEvent(eventClickInfo) {
      eventClickInfo.jsEvent.preventDefault()
      this.$router.push(eventClickInfo.event.url)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc-event {
  background: $ford-primary;
  // border: 1px solid #fff !important;
  border-color: #fff !important;
  transition: 0.15s;

  &:hover {
    background: rgba(0, 0, 0, 0.1) !important;

    .fc-event-main {
      transition: 0.15s;
      color: $ford-primary !important;
    }
  }
}

.app-calendar .fc .fc-event .fc-event-main {
  color: #fff;
}

.loading-overlay {
  position: absolute;
  z-index: 100;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.fc-event-time, .fc-daygrid-event-dot {
  display: none;
}

.fc-daygrid-dot-event {
  background: $ford-primary;
  // border: 1px solid #fff !important;
  border-color: #fff !important;
  color: #fff;
  padding: 4.5px 7px !important;
}

.fc .fc-daygrid-day-events {
  margin-top: 0;
}

.fc .fc-daygrid-event-harness + .fc-daygrid-event-harness {
  margin-top: 0;
}
</style>
